body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (max-width: 500px) {
  body {
    overflow-x: hidden;
  }
}
html,
body {
  overflow-x: hidden;
  width: 100%;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  height: 5rem;
  margin: 2rem auto;
  width: 80%;
  font-family: "Open sans", sans-serif;
}
.navbar h1 {
  position: absolute;
  left: 10%;
}
.navbar-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 5rem;
}

.navbar-item {
  list-style: none;

  margin-left: 3rem;
}
.navbar-item:hover {
}
.navbar-link:hover {
  background-color: #b0855b;
  color: white;
}
.navbar-link-active {
  text-decoration: none;
  font-size: 20px;
  color: black;
  font-weight: 600;
  padding: 5px;
  border-radius: 10px;
  font-weight: 400;
  background-color: #b0855b;
  color: white;
}

.navbar-link {
  text-decoration: none;
  font-size: 20px;
  color: black;
  font-weight: 600;
  padding: 5px;
  border-radius: 10px;
  font-weight: 400;
}

.navbar-logo {
  border-radius: 50px;
  margin-top: -2rem;
  margin-right: 10rem;
  cursor: pointer;
}

@media (max-width: 770px) {
  .navbar {
    margin-left: 11rem;
  }
  .navbar-link {
    font-size: 18px;
  }
  .navbar-item {
    margin-left: 1rem;
  }
}
@media (max-width: 550px) {
  .navbar {
    margin-left: 6rem;

    /* justify-content: center; */
  }
  .navbar-items {
  }
  .navbar-link {
    font-size: 16px;
  }
  .navbar-item {
    margin-left: 0.5rem;
  }
  .navbar-logo {
    transform: scale(0.5);
    margin-inline-end: 8rem;
    margin-top: -3rem;
  }
}
@media (max-width: 321px) {
  .navbar {
    margin-left: 5rem;
  }

  .navbar-link {
    font-size: 14px;
  }

  .navbar-logo {
    transform: scale(0.4);
    margin-inline-end: 6.5rem;
  }
}
@media (max-width: 768px) {
  .navbar {
    justify-content: center;
    width: 60%;
  }
  .navbar-items {
    margin-left: -9rem;
  }
  .navbar-logo {
  }
}
@media (max-width: 860px) {
  .navbar {
    justify-content: center;
    width: 60%;
  }
  .navbar-items {
    margin-left: -9rem;
  }
  .navbar-logo {
  }
}

.img-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5rem;
  overflow: hidden;
  width: 100%;
}
.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  height: 180px;

  animation: scroll 15s linear infinite;
}
.slider:hover {
  animation-play-state: paused;
}

.slider-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 3rem;
}

.slider-container-link a {
  text-decoration: none;
  background-color: green;
  color: white;
  padding: 6px;
  border-radius: 10px 0 10px 0;
  font-weight: 400;
  font-family: "Open sans", sans-serif;
}
.slider-container-link {
  margin-bottom: 1rem;
}

.slider-container-link a:hover {
  background-color: rgb(58, 148, 58);
  box-shadow: 5px 5px 30px 1px green;
}
.slider-container-link a:active .slider-container-link {
  transform: scale(1.05);
}

.slide img {
  border-radius: 8px;
  width: 90%;
  height: 200px;
}
@media (max-width: 1144px) {
  .slider-container {
    width: 90%;
  }
  .slide img {
    border-radius: 8px;
    width: 90%;
    height: 150px;
  }
}
@media (max-width: 800px) {
  .slide img {
    border-radius: 8px;
    width: 90%;
    height: 140px;
  }
  .slider-container {
    width: 90%;
  }
}
@media (max-width: 821px) {
  .slide img {
    border-radius: 8px;
    width: 90%;
    height: 140px;
  }
  .slider-container {
    width: 90%;
  }
}
@media (max-width: 600px) {
  .slider-container {
    width: 60%;
  }
  .slide img {
    width: 100%;
    height: 13rem;
  }
}
@media (max-width: 480px) {
  .slider-container {
    width: 70%;
  }
  .slide img {
    width: 100%;
    height: 12rem;
  }
}
@media (max-width: 420px) {
  .slider-container {
    width: 80%;
  }
  .slide img {
    width: 100%;
    height: 11rem;
  }
}
@media (max-width: 360px) {
  .slider-container {
    width: 100%;
  }
  .slide img {
    margin: 0 auto;
    width: 80%;
    height: 13rem;
  }
  .slider-container-link {
    margin-left: 2rem;
  }
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;

  margin-bottom: 2rem;
}
.header-link {
  width: 8rem;
  transition: all 0.3s;
  margin-top: 1rem;

  background-color: green;
  border-radius: 10px 0 10px 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 25px;
}
.header-link:hover {
  background-color: rgb(58, 148, 58);
  box-shadow: 5px 5px 30px 1px green;
}
.header-link:hover {
  transform: scale(1.05);
}
.header-link:active {
  transform: translateY(10%);
}

.header-link a {
  text-decoration: none;
  /* background-color: green; */
  color: white;
  font-size: 1.2rem;
  /* padding: 5px 25px; */
  border-radius: 10px 0 10px 0;

  font-weight: 400;
  font-family: "Open sans", sans-serif;

  margin: auto;
}

@keyframes forTitle {
  0% {
    /* transform: translateX(-50%); */

    opacity: 0;
  }
  100% {
    /* transform: translateX(0); */

    opacity: 1;
  }
}
.header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  animation: forTitle 0.7s ease-out forwards;
  width: 50%;
}

.header-title h1 {
  /* font-size: 1.8rem; */
  /* width: 100%; */
  text-transform: uppercase;
  margin-top: 5rem;
  font-family: "Poppins", sans-serif;
}
.header-title p {
  /* font-size: 14px; */
  /* width: 90%; */
  color: #646464;
  margin-top: -0.5rem;
  font-family: "Open sans", sans-serif;
  margin-top: 0.5rem;
}
@media (max-width: 860px) {
  .header-items {
    flex-direction: column-reverse;
  }
  .header-img {
    margin: 0 auto;
    position: relative;
  }

  .header-title {
    margin: 0 auto;
    width: 70%;
  }
  .header-title h1 {
    margin-top: -2rem;
    font-size: 1.5rem;
  }
  .header-link {
    width: 100%;
    height: 2rem;
  }
}
@media (max-width: 650px) {
  .header-items {
    margin-left: 2rem;
  }
  .header-title {
    width: 80%;
  }
  .header-title h1 {
    font-size: 1.7rem;
  }
  .header-link a {
    /* padding: 5px 10rem; */
    /* font-size: 24px;
    font-weight: 500; */
  }
}
@media (max-width: 550px) {
  .header-title h1 {
    font-size: 1.3rem;
  }
  .header-link a {
    /* padding: 5px 100px; */
  }
}
@media (max-width: 480px) {
  .header-link a {
    /* padding: 5px 7rem; */
  }
  .header {
    margin-top: -2rem;
  }
  .header-items img {
  }
  .header-img-border {
  }
}
@media (max-width: 435px) {
  .header-title h1 {
    font-size: 1.2rem;
  }
  .header-title p {
    font-size: 10px;
  }
  .header-title {
    width: 100%;
  }
  .header-img img {
    min-width: 300px;
    height: 270px;
    max-width: 300px;
  }
  .header-img-border {
    min-width: 300px;
    height: 270px;
    max-width: 400px;
  }
  .header-link a {
    /* padding: 3px 120px; */
  }
}
@media (max-width: 321px) {
  .header-img img {
    transform: scale(0.8);
    margin-left: -2rem;
  }
  .header-img-border {
    transform: scale(0.8);
    margin-left: -2rem;
  }
  .header-title {
    margin-top: -3rem;
    width: 100%;
  }
  .header-title h1 {
    font-size: 1rem;
  }
  .header-link a {
    /* padding: 3px 90px; */
  }
  .header {
    margin-top: -5rem;
  }

  /* .header-title p {
    font-size: 0.7rem;
    width: 70%;
  } */
}

.header-img {
  position: relative;
  margin: 0 auto;
}
.header-img img {
  width: 100%;
  /* height: 60%; */
  z-index: 1;
  border-radius: 100px 0 100px 0;
}
.header-items {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
  width: 80%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin: 2rem auto;
}

.header-img-border {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5%;
  left: 5%;
  border: 2px solid #b0855b;
  border-radius: 100px 0 100px 0;
  z-index: -1;
}

.service {
  margin-top: 3rem;
  width: 80%;
  margin: auto;
}
.service-text {
  /* margin-left: 8rem; */
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}
.service-text h2 {
  font-size: 16px;
  color: #646464;
  text-transform: uppercase;
  margin-left: 0.1rem;
}
.service-text h1 {
  margin-top: 0.1rem;
  font-size: 40px;
  font-weight: 500;
}
.service-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 1rem;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 20px 0 20px 0;
  box-shadow: 1px 7px 10px rgb(161, 159, 159);
  width: 300px;
  height: 150px;
  background-color: #efe0d6;
  color: rgb(86, 85, 85);
  transition: all 0.5s;
}

.service-item h1 {
  font-size: 20px;
  margin-left: 2rem;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  font-family: "Open sans", sans-serif;
}
.service-item-icon {
  margin-right: 0.5rem;
  transition: all 0.3s;
}
.service-item:nth-of-type(1):hover .service-item-icon {
  color: rgb(53, 87, 172);
  transform: scale(1.5);
}
.service-item:nth-of-type(2):hover .service-item-icon {
  color: rgb(155, 108, 46);
  transform: scale(1.3);
}
.service-item:nth-of-type(3):hover .service-item-icon {
  color: rgb(246, 211, 71);
  transform: scale(1.5);
}
.service-item p {
  font-size: 16px;
  margin-left: 2rem;
  margin-bottom: 1rem;
  margin-right: 1.4rem;
  height: 100px;
  font-family: "Open sans", sans-serif;
}
.service-item:hover {
  transform: translateY(-5%);
}

@media (max-width: 1024px) {
  .service {
    width: 90%;
    margin: 0 auto;
  }

  .service-text h1 {
    /* margin-left: -7.5rem; */
  }

  .service-text h2 {
    /* margin-left: -7.5rem; */
  }
  .service-item h1 {
    font-size: 1rem;
  }
  .service-item p {
    font-size: 14px;
  }
}
@media (max-width: 860px) {
  .service {
    width: 90%;
    /* margin-left: 8rem; */
    margin-top: 2rem;
  }

  .service-text h1 {
    /* margin-left: -7.5rem; */
  }

  .service-text h2 {
    /* margin-left: -7.5rem; */
  }
  .service-item {
    height: 110px;
  }
  .service-item h1 {
    font-size: 14px;
    margin-left: 1rem;
    /* margin: 0 auto;
    margin-top: 1rem; */
  }

  .service-item p {
    font-size: 12px;
    margin-left: 1rem;
  }
}

@media (max-width: 680px) {
  .service-items {
    display: flex;
    flex-direction: column;
  }
  .service-item {
    width: 50%;
    height: 90px;
  }
  .service {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .service-text h1 {
    font-size: 26px;
  }
  .service-text h2 {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .service-item {
    width: 80%;
  }
  .service-text h1 {
    font-size: 18px;
  }
  .service-text h2 {
    font-size: 10px;
  }
}
.footer {
  background-color: #ebebeb;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.footer-logo {
  /* margin-top: 4rem;
  margin-left: 4rem; */
  position: absolute;
  left: 10%;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  /* margin-left: 20rem;
  margin-right: 5rem; */

  margin-right: 5rem;
}

.footer-links h3 {
  font-weight: 500;
  margin-bottom: 0rem;
}
.footer-list {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  margin-top: 0.8rem;

  /* margin-right: 5rem; */
}

.footer-item {
  list-style: none;
  margin-bottom: 0.7rem;
  color: #646464;
}
.footer-item a {
  text-decoration: none;
  color: #646464;
}

.footer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  /* margin-right: 5rem; */
}
.footer-info h3 {
  font-weight: 500;
  margin-left: -5rem;
}
.footer-info li {
  font-size: 14px;
}
.footer-logo {
  border-radius: 50px;
  margin-top: 10px;

  color: #646464;
}
@media (max-width: 380px) {
  .footer-links {
    font-size: 10px;
    /* width: 50%; */
    /* margin-left: 12rem; */
  }
  .footer-info {
    font-size: 10px;
    /* width: 40%; */
    /* margin-left: -5rem; */
  }
}
@media (max-width: 600px) {
  .footer {
    justify-content: space-around;
  }
  .footer-links {
    font-size: 12px;
    /* margin-left: 12rem; */
    margin-right: 0;
  }
  .footer-link {
    /* margin-left: 1rem; */
  }
  .footer-info {
    font-size: 12px;
    /* margin-left: -7rem; */
  }
}
@media (max-width: 768px) {
  .footer {
    /* justify-content: center;
    position: relative; */
    /* margin-left: 5rem; */
  }
  .footer-logo {
    /* margin-left: 25rem; */
    /* position: absolute;
    left: -5%; */
    opacity: 0;
    position: absolute;

    z-index: -10;
  }
  .footer-links {
  }
  .footer-info {
    /* margin-right: 10rem; */
  }
}
@media (max-width: 880px) {
  .footer {
    /* justify-content: center; */
    position: relative;
    /* margin-left: 5rem; */
  }
  .footer-logo {
    /* margin-left: 25rem; */
    /* position: absolute;
    left: -5%; */
    left: 2%;
  }
  .footer-links {
  }
  /* .footer-info {
    margin-right: 10rem;
  } */
}
.image-grid {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin: 5rem auto;
  width: 90%;
  height: 60%;
  flex-wrap: wrap;
  border: 2px solid #b0855b;
  border-radius: 10px;
  padding: 27px 0;
}

.image-grid img {
  width: 200px;
  height: 150px;
  border-radius: 10px;
  transition: transform 0.3s ease-out;
  animation: forGallery 0.3s ease-out forwards;
  z-index: 0;
}
.image-grid img:hover {
  transform: translateY(-5%) scale(1.3);
  cursor: pointer;
  z-index: 9999999;
}
.gallery-img:hover {
  transform: translateY(-5%);
  transform: scale(1.1);
  cursor: pointer;
}
.gallery-img {
  position: relative;
  /* width: 200px; 
  height: 200px; */
  margin: 10px;
  transition: all 0.2s;
}
@media (max-width: 700px) {
  .image-grid img {
    width: 350px;
    height: 250px;
  }
}
@media (max-width: 480px) {
  .image-grid img {
    width: 200px;
    height: 150px;
  }
}
@media (max-width: 380px) {
  .image-grid {
    margin: 0 auto;
  }
  .image-grid img {
    width: 200px;
    height: 150px;
  }
}
.clicked {
  transform: scale(2);
  z-index: 9999999;
}
@keyframes forGallery {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.about {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-wrap: wrap;
  width: 70%;
  margin: 5rem auto;
  padding: 2rem;
  border: 2px solid #f0e3d7;
  border-radius: 10px;
  font-family: "Open sans", sans-serif;
  background-color: #f0e3d7;
  transition: all 0.8s;
}

@keyframes forAbout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.about p {
  animation: forAbout 0.5s ease-out forwards;
  margin-bottom: 0.5rem;
}

.about li span {
  font-weight: 600;
}
.about p span {
  font-weight: 600;
}
.about div {
  border-bottom: 2px solid rgb(179, 177, 177);
}

.about-us__content {
  max-height: 0;
  /* opacity: 0; */
  overflow: hidden;
  transition: all 0.5s;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.about-us__content-active {
  max-height: 11rem;
  /* opacity: 1; */
  overflow: hidden;
  /* padding-top: 10px; */
  /* padding-bottom: 20px; */
  transition: all 0.5s;
}
.about-us-icon {
  transition: all 0.5s;
}
.about-us-icon-rotate {
  transform: rotate(180deg);
  transition: all 0.5s;
}
.who__content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.who__content-active {
  max-height: 17rem;
  opacity: 1;
  overflow: hidden;
  /* padding-top: 10px; */
  /* padding-bottom: 20px; */
  transition: all 0.5s;
}
.who-icon {
  transition: all 0.5s;
}
.who-icon-rotate {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.what__content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.what__content-active {
  max-height: 25rem;
  opacity: 1;
  overflow: hidden;
  /* padding-top: 10px; */
  /* padding-bottom: 20px; */
  transition: all 0.5s;
}
.what-icon {
  transition: all 0.5s;
}
.what-icon-rotate {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.why__content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.why__content-active {
  max-height: 25rem;
  opacity: 1;
  overflow: hidden;
  /* padding-top: 10px; */
  /* padding-bottom: 20px; */
  transition: all 0.5s;
}
.why-icon {
  transition: all 0.5s;
}
.why-icon-rotate {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.our__content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.our__content-active {
  max-height: 20rem;
  opacity: 1;
  overflow: hidden;
  /* padding-top: 10px; */
  /* padding-bottom: 20px; */
  transition: all 0.5s;
}
.our-icon {
  transition: all 0.5s;
}
.our-icon-rotate {
  transform: rotate(180deg);
  transition: all 0.5s;
}
@media (max-width: 890px) {
  /* .about-us__content-active {
    height: 7rem;
  } */
}

@media (max-width: 670px) {
}
@media (max-width: 450px) {
  /* .about-us__content-active {
    height: 11rem;
  } */
}
@media (max-width: 550px) {
  .about h1 {
    font-size: 20px;
  }

  .about p {
    font-size: 11px;
  }
}
@media (max-width: 400px) {
  .about h1 {
    font-size: 14px;
  }

  .about p {
    font-size: 10px;
  }
}

.contact {
  margin: 5rem auto;
  background-color: #f0e3d7;
  font-family: "Open sans", sans-serif;
  padding: 2px;
  border-radius: 10px;
  width: 90%;
}

.contact-info h3 {
  font-size: 42px;
  margin-left: 2rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.contact-info li {
  font-size: 28px;
  list-style: none;
}
.contact-item {
  margin-bottom: 2rem;
  color: #646464;
}
.contact-icon {
  width: 4rem;
}
.whatsapp-icon {
  margin-left: 0.5rem;
}
@media (max-width: 480px) {
  .contact-info h3 {
    font-size: 24px;
  }
  .contact-info li {
    font-size: 16px;
  }
  .contact-icon {
    margin-right: -1.2rem;
  }
}

.up-btn {
  position: absolute;
  position: fixed;
  right: 5%;
  top: 80%;
  border: none;
  border-radius: 15%;
  padding: 0.5rem 1rem;
  font-size: 26px;
  font-weight: 600;
  cursor: pointer;
  background-color: orange;
  opacity: 0;
  transition: all 0.2s;
}
.up-btn:active {
  transform: scale(1.2);
}
.active {
  opacity: 1;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.header-spinner {
  width: 80px;
  height: 80px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}
.header-spinner-wrapper {
  position: relative;
  width: 132%;
  height: 130%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: white;
}
@media (max-width: 860px) {
  .header-spinner-wrapper {
    height: 110%;
    width: 130%;
    top: 50%;
    left: 40%;
  }
}
@media (max-width: 440px) {
  .header-spinner {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 320px) {
  .header-spinner-wrapper {
    height: 100%;
    top: 50%;
    left: 30%;
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
